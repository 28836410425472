import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';

import { useCountry, useLanguage } from '../../../../contexts/LocaleContext';
import { fetchCountryNationality } from '../../../../utils/api';
import { toInt } from '../../../../utils/number';
import useFetchAuth from '../../../../utils/useFetchAuth';
import useFormatNumber from '../../../../utils/useFormatNumber';
import { checkBusinessId, checkIncomeExpense } from '../../../../utils/validators';
import {
	CheckboxField,
	Ranger,
	SelectField,
	TextField,
} from '../../../forms';
import { Alert, Button, Tooltip } from '../../../ui';
import InvestorProfileChoice from '../../InvestorProfileChoice';

import styles from './StepQuestions.module.scss';

const useFetchCountryNationality = useFetchAuth(fetchCountryNationality);

const RANGER_YEARS_MIN = 1;
const RANGER_YEARS_MAX = 40;
const RANGER_ESG_MIN = 0;
const RANGER_ESG_MAX = 100;
const ESG_FINAL_QUESTION_ID = 37;
const INCOME_QUESTION_ID = 44;
const EXPENSE_QUESTION_ID = 45;

const subpartQuestions = {
	19: {
		answerGroups: [
			{
				answerIds: [54, 56, 57, 207, 219, 220, 221, 222, 223],
				skipQuestionIds: [38, 70],
			},
			{
				answerIds: [55],
				skipQuestionIds: [70],
			},
			{
				answerIds: [58],
				skipQuestionIds: [38],
			},
		],
	},
	20: {
		answerGroups: [
			{
				answerIds: [59, 60, 61, 62, 208, 224, 225, 226, 227],
				skipQuestionIds: [71],
			},
		],
	},
	21: {
		answerGroups: [
			{
				answerIds: [
					64, 66, 68, 69, 70, 72, 74, 75, 76,
					77, 78, 79, 80, 84, 85, 86, 87, 89,
					90, 91, 92, 93, 94, 96, 97, 98, 99,
					100, 101, 102, 103, 104, 106, 107, 108, 109,
				],
				skipQuestionIds: [39],
			},
		],
	},
	29: { answerGroups: [{ answerIds: [124], skipQuestionIds: [30] }] },
	31: { answerGroups: [{ answerIds: [126], skipQuestionIds: [32] }] },
	33: { answerGroups: [{ answerIds: [128], skipQuestionIds: [34] }] },
	35: { answerGroups: [{ answerIds: [130], skipQuestionIds: [36] }] },
	49: { answerGroups: [{ answerIds: [146], skipQuestionIds: [50, 51, 52, 53, 54, 55, 56] }] },
};

function toIntOrNull(value) {
	const intValue = toInt(value);
	return Number.isNaN(intValue) ? null : intValue;
}

function toAnswer({ type }, value) {
	switch (type) {
		case 'yearsSlider':
			return [value, null, value, null];
		case 'ratioSlider':
			return [value, null, value, null];
		case 'numberInput': {
			const answerValue = value !== '' ? toIntOrNull(value) : null;
			return [answerValue, null, answerValue, null];
		}
		case 'textInput':
			return [value, null, null, value];
		case 'businessId':
			return [value, null, null, value];
		case 'singleChoice': {
			const answerId = value !== null ? toIntOrNull(value) : null;
			return [answerId, answerId, null, null];
		}
		case 'countrySelection':
			return [value, null, null, value];
		default:
			return [null, null, null, null];
	}
}

function toFormValue({ type, userAnswer }, answer) {
	switch (type) {
		case 'yearsSlider':
			return answer ?? userAnswer?.value ?? RANGER_YEARS_MIN;
		case 'ratioSlider':
			return answer ?? userAnswer?.value ?? RANGER_ESG_MIN;
		case 'numberInput':
			return String(answer ?? userAnswer?.value ?? '');
		case 'textInput':
			return String(answer ?? userAnswer?.text ?? '');
		case 'businessId':
			return String(answer ?? userAnswer?.text ?? '');
		case 'singleChoice': {
			const answerId = answer ?? userAnswer?.id ?? null;
			return answerId !== null ? String(answerId) : null;
		}
		case 'countrySelection':
			return String(answer ?? userAnswer?.text ?? '');
		default:
			return undefined;
	}
}

function toFormValues(questions, answers) {
	return questions.reduce((acc, question) => {
		const value = toFormValue(question, answers[question.id]);
		if (value !== undefined) {
			acc[question.id] = value;
		}
		return acc;
	}, {});
}

function questionAnswers({ answers }) {
	return [...answers].sort((a, b) => a.position - b.position);
}

function questionAnswerOptions({ answers }, language) {
	const sortFn = answers.length >= 20
		? (a, b) => (a.answer ?? '').localeCompare(b.answer ?? '', language)
		: (a, b) => a.position - b.position;

	return [...answers].sort(sortFn).map((answer) => ({
		label: answer.answer,
		value: String(answer.id),
	}));
}

function showAnswerSelectField({ answers }) {
	return answers?.length >= 10;
}

function AutoSubmit({
	name,
	setFieldValue,
	submitForm,
}) {
	useEffect(() => {
		setFieldValue(name, false, false);
		submitForm();
	}, [name, setFieldValue, submitForm]);

	return null;
}

export default function StepQuestions({
	answers,
	childId,
	closeAccount,
	disabled,
	faqUrl,
	investmentQuestionnaireResetAvailable,
	overwriteEsgPreferenceAnswers,
	product,
	questions,
	reloadQuestionnaire,
	resetEsgPreferenceQuestionnaire,
	resetPosition,
	saveQuestionnaireAnswer,
	saveQuestionnaireAnswerResult,
}) {
	const [t] = useTranslation();
	const country = useCountry();
	const language = useLanguage();
	const [error, setError] = useState(false);
	const [esgPreferenceWarning, setEsgPreferenceWarning] = useState(false);
	const [nationality, loadingNationality] = useFetchCountryNationality(null, country, language);
	const formatPercent = useFormatNumber({ style: 'percent' });

	if (loadingNationality) {
		return null;
	}

	const initAnswers = toFormValues(questions, answers);
	const getValue = ({ id }, values) => (id in values.answers ? values.answers[id] : initAnswers[id]);

	const getSkipQuestionIds = (values, questionId = null, nextAnswerId = null) => questions.reduce((acc, question) => {
		const { id } = question;
		const answerId = id === questionId ? nextAnswerId : toAnswer(question, getValue(question, values))[1];
		const subpartGroups = subpartQuestions[id]?.answerGroups || [];

		if (answerId === null) {
			const allSkipQuestionIds = subpartGroups.reduce((groupAcc, group) => {
				group.skipQuestionIds.forEach((skipQuestionId) => {
					if (!groupAcc.includes(skipQuestionId)) {
						groupAcc.push(skipQuestionId);
					}
				});
				return groupAcc;
			}, []);

			return acc.concat(allSkipQuestionIds);
		}

		const subpartGroup = subpartGroups.find((group) => group.answerIds.includes(answerId));

		return subpartGroup
			? acc.concat(subpartGroup.skipQuestionIds)
			: acc;
	}, []);

	const isAutoSubmit = (skipQuestionIds) => {
		const activeQuestions = questions.filter(({ id }) => !skipQuestionIds.includes(id));
		return activeQuestions.length === 1 && activeQuestions.every((question) => (
			question.type === 'singleChoice'
			&& question.answers
			&& !showAnswerSelectField(question)
			&& question.id !== ESG_FINAL_QUESTION_ID
		));
	};

	const handleChoiceClick = (setFieldValue, values, skipQuestionIds, questionId, answerId) => {
		const nextSkipQuestionIds = subpartQuestions[questionId]
			? getSkipQuestionIds(values, questionId, answerId)
			: skipQuestionIds;

		const subpartGroups = subpartQuestions[questionId]?.answerGroups || [];
		const subpartGroup = subpartGroups.find((group) => group.answerIds.includes(answerId));

		if (subpartGroup) {
			subpartGroup.skipQuestionIds.forEach((skipQuestionId) => {
				const skipQuestion = questions.find(({ id }) => id === skipQuestionId);
				const value = skipQuestion ? toFormValue({ type: skipQuestion.type, userAnswer: null }, null) : null;
				setFieldValue(`answers[${skipQuestionId}]`, value, false);
			});
		}

		setFieldValue('autosubmit', isAutoSubmit(nextSkipQuestionIds), false);
	};

	const nationalityOptions = nationality.map((item) => ({
		label: item.name,
		value: item.code,
	}));

	return (
		<>
			<Formik
				className={styles.root}
				enableReinitialize
				initialValues={{
					answers: initAnswers,
					autosubmit: false,
					esgPreferenceDeclaration: false,
				}}
				onSubmit={async (values) => {
					setError(false);

					const skipQuestionIds = getSkipQuestionIds(values);
					let result = null;

					for (let i = 0; i < questions.length; i += 1) {
						const question = questions[i];

						if (skipQuestionIds.includes(question.id)) {
							continue; // eslint-disable-line no-continue
						}

						const [
							answer,
							answerId,
							answerVal,
							answerText,
						] = toAnswer(question, getValue(question, values));
						try {
							// eslint-disable-next-line no-await-in-loop
							result = await saveQuestionnaireAnswer(
								question.id,
								answer,
								answerId,
								answerVal,
								answerText,
							);
						} catch {
							setError(true);
							return;
						}
					}

					if (result !== null) {
						saveQuestionnaireAnswerResult(result);
					}
				}}
				validate={(values) => {
					const skipQuestionIds = getSkipQuestionIds(values);
					return questions.filter((question) => (
						!skipQuestionIds.includes(question.id)
						&& question.type === 'singleChoice'
						&& toAnswer(question, getValue(question, values))[1] === null
					)).reduce((acc, { id }) => {
						acc.answers = acc.answers || {};
						acc.answers[id] = 'onboarding.steps.questionnaire.select';
						return acc;
					}, {});
				}}
			>
				{({
					errors,
					handleBlur,
					handleChange,
					handleSubmit,
					isSubmitting,
					setFieldValue,
					submitForm,
					touched,
					values,
				}) => ((skipQuestionIds) => (
					<form onSubmit={handleSubmit}>
						{error && (
							<Alert type="danger">
								{t('forms.error')}
							</Alert>
						)}
						{values.autosubmit && (
							<AutoSubmit name="autosubmit" setFieldValue={setFieldValue} submitForm={submitForm} />
						)}
						{questions.filter(({ id }) => !skipQuestionIds.includes(id)).map((question) => (
							<Fragment key={question.id}>
								{question.type === 'singleChoice' && question.answers && (
									<>
										{question.id === ESG_FINAL_QUESTION_ID && (
											<>
												<h3 className={styles.question}>
													{t('onboarding.steps.questionnaire.esgFinalQuestion.title')}
												</h3>
												<Trans i18nKey="onboarding.steps.questionnaire.esgFinalQuestion.text">
													<p className={styles.text} />
												</Trans>
											</>
										)}
										<h3 className={styles.question}>{question.question}</h3>
										{question.description && (
											<div
												className={styles.text}
												// eslint-disable-next-line react/no-danger
												dangerouslySetInnerHTML={{ __html: question.description }}
											/>
										)}
										{showAnswerSelectField(question) ? (
											<div className={styles.singleChoiceMany}>
												<SelectField
													disabled={disabled || isSubmitting}
													error={
														errors.answers?.[question.id]
														&& touched.answers?.[question.id]
														&& t(errors.answers[question.id])
													}
													id={`answers[${question.id}]`}
													isLabelHidden
													label={question.question}
													name={`answers[${question.id}]`}
													onBlur={handleBlur}
													onChange={setFieldValue}
													options={questionAnswerOptions(question, language)}
													placeholder={t('onboarding.steps.questionnaire.select')}
													value={getValue(question, values)}
												/>
											</div>
										) : (
											<div className={styles.singleChoice}>
												{questionAnswers(question).map((answer) => (
													<label
														key={answer.id}
														htmlFor={`answers[${question.id}]__${answer.id}`}
														className={styles.label}
													>
														<input
															checked={
																getValue(question, values) === String(answer.id)
															}
															className={styles.input}
															disabled={disabled || isSubmitting}
															id={`answers[${question.id}]__${answer.id}`}
															name={`answers[${question.id}]`}
															onBlur={handleBlur}
															onChange={handleChange}
															onClick={() => handleChoiceClick(
																setFieldValue,
																values,
																skipQuestionIds,
																question.id,
																answer.id,
															)}
															type="radio"
															value={String(answer.id)}
														/>
														<span className={styles.button}>
															{answer.answer}
														</span>
													</label>
												))}
												{errors.answers?.[question.id] && touched.answers?.[question.id] && (
													<div className={styles.error}>
														{t(errors.answers[question.id])}
													</div>
												)}
											</div>
										)}
										{question.id === ESG_FINAL_QUESTION_ID && (
											<div className={styles.offsetWrap}>
												<CheckboxField
													checked={values.esgPreferenceDeclaration}
													disabled={disabled || isSubmitting}
													error={(!values.esgPreferenceDeclaration && esgPreferenceWarning) ? t('forms.fields.esgPreferenceDeclaration.error') : ''}
													label={(
														<Trans i18nKey="onboarding.steps.questionnaire.esgFinalQuestion.declaration" components={[<p className={styles.checkboxText} />]} />
													)}
													id="esgPreferenceDeclaration"
													name="esgPreferenceDeclaration"
													onBlur={handleBlur}
													onChange={handleChange}
													required
												/>
												<div className={styles.buttonWrap}>
													<Button
														disabled={disabled || isSubmitting}
														isLong
														label={t('onboarding.steps.questionnaire.esgFinalQuestion.button.continue')}
														onClick={() => {
															if (values.esgPreferenceDeclaration) {
																overwriteEsgPreferenceAnswers(childId);
																setEsgPreferenceWarning(false);
																handleSubmit();
															} else {
																setEsgPreferenceWarning(true);
															}
														}}
													/>
													<Button
														disabled={disabled || isSubmitting}
														isLong
														label={t('onboarding.steps.questionnaire.esgFinalQuestion.button.change')}
														onClick={() => {
															if (values.esgPreferenceDeclaration) {
																resetEsgPreferenceQuestionnaire(childId);
																setEsgPreferenceWarning(false);
																resetPosition();
															} else {
																setEsgPreferenceWarning(true);
															}
														}}
														outline
													/>
													<Button
														disabled={disabled || isSubmitting}
														isLink
														label={t('onboarding.steps.questionnaire.esgFinalQuestion.button.cancel')}
														onClick={closeAccount}
													/>
												</div>
											</div>
										)}
										{question.note && (
											<div
												className={styles.note}
												// eslint-disable-next-line react/no-danger
												dangerouslySetInnerHTML={{ __html: question.note }}
											/>
										)}
									</>
								)}
								{question.type === 'yearsSlider' && (
									<>
										<h3 className={styles.question}>{question.question}</h3>
										{question.description && (
											<div
												className={styles.text}
												// eslint-disable-next-line react/no-danger
												dangerouslySetInnerHTML={{ __html: question.description }}
											/>
										)}
										<div className={styles.slider}>
											<Ranger
												disabled={disabled || isSubmitting}
												id="ranger"
												max={RANGER_YEARS_MAX}
												maxLabel={t('onboarding.steps.questionnaire.years', { count: RANGER_YEARS_MAX })}
												min={RANGER_YEARS_MIN}
												minLabel={t('onboarding.steps.questionnaire.years', { count: RANGER_YEARS_MIN })}
												name={`answers[${question.id}]`}
												onChange={setFieldValue}
												value={getValue(question, values)}
											/>
										</div>
										{question.note && (
											<div
												className={styles.note}
												// eslint-disable-next-line react/no-danger
												dangerouslySetInnerHTML={{ __html: question.note }}
											/>
										)}
									</>
								)}
								{question.type === 'numberInput' && (
									<>
										<h3 className={styles.question}>{question.question}</h3>
										{question.description && (
											<div
												className={styles.text}
												// eslint-disable-next-line react/no-danger
												dangerouslySetInnerHTML={{ __html: question.description }}
											/>
										)}
										<div className={styles.inputWrap}>
											<TextField
												disabled={disabled || isSubmitting}
												id={`answers[${question.id}]`}
												isLabelHidden
												label={question.question}
												name={`answers[${question.id}]`}
												onBlur={handleBlur}
												onChange={handleChange}
												pattern="[0-9]+"
												required
												type="text"
												value={getValue(question, values)}
												warning={
													(
														touched.answers?.[question.id]
														|| (initAnswers[question.id] ?? '') !== ''
													) && (
														question.id === INCOME_QUESTION_ID
														|| question.id === EXPENSE_QUESTION_ID
													) && !checkIncomeExpense(getValue(question, values) ?? '', country)
														? t('forms.fields.incomeExpense.warning')
														: ''
												}
											/>
											<div className={styles.currency}>
												{t('onboarding.steps.questionnaire.currency')}
											</div>
										</div>
										{question.note && (
											<div
												className={styles.note}
												// eslint-disable-next-line react/no-danger
												dangerouslySetInnerHTML={{ __html: question.note }}
											/>
										)}
									</>
								)}
								{question.type === 'businessId' && (
									<>
										<h3 className={styles.question}>{question.question}</h3>
										{question.description && (
											<div
												className={styles.text}
												// eslint-disable-next-line react/no-danger
												dangerouslySetInnerHTML={{ __html: question.description }}
											/>
										)}
										<div className={styles.inputWrap}>
											<TextField
												disabled={disabled || isSubmitting}
												id={`answers[${question.id}]`}
												isLabelHidden
												label={question.question}
												name={`answers[${question.id}]`}
												onBlur={handleBlur}
												onChange={handleChange}
												pattern="[0-9]+"
												required
												type="text"
												value={getValue(question, values)}
												warning={
													touched.answers?.[question.id]
													&& !checkBusinessId(getValue(question, values), country)
														? t('forms.fields.businessId.warning')
														: ''
												}
											/>
										</div>
										{question.note && (
											<div
												className={styles.note}
												// eslint-disable-next-line react/no-danger
												dangerouslySetInnerHTML={{ __html: question.note }}
											/>
										)}
									</>
								)}
								{question.type === 'text' && (
									<>
										<h3 className={styles.question}>{t('onboarding.steps.questionnaire.amlTitle')}</h3>
										{question.description && (
											<div
												className={styles.text}
												// eslint-disable-next-line react/no-danger
												dangerouslySetInnerHTML={{ __html: question.description }}
											/>
										)}
										<p className={styles.text}>
											{question.question}
										</p>
										{question.note && (
											<div
												className={styles.note}
												// eslint-disable-next-line react/no-danger
												dangerouslySetInnerHTML={{ __html: question.note }}
											/>
										)}
									</>
								)}
								{question.type === 'textInput' && (
									<>
										<h3 className={styles.question}>{question.question}</h3>
										{question.description && (
											<div
												className={styles.text}
												// eslint-disable-next-line react/no-danger
												dangerouslySetInnerHTML={{ __html: question.description }}
											/>
										)}
										<div className={styles.inputWrap}>
											<TextField
												disabled={disabled || isSubmitting}
												id={`answers[${question.id}]`}
												isLabelHidden
												label={question.question}
												name={`answers[${question.id}]`}
												onBlur={handleBlur}
												onChange={handleChange}
												pattern="[A-zÀ-ž\s]+"
												required
												type="text"
												value={getValue(question, values)}
											/>
										</div>
										{question.note && (
											<div
												className={styles.note}
												// eslint-disable-next-line react/no-danger
												dangerouslySetInnerHTML={{ __html: question.note }}
											/>
										)}
									</>
								)}
								{question.type === 'countrySelection' && (
									<>
										<h3 className={styles.question}>{question.question}</h3>
										{question.description && (
											<div
												className={styles.text}
												// eslint-disable-next-line react/no-danger
												dangerouslySetInnerHTML={{ __html: question.description }}
											/>
										)}
										<div className={styles.singleChoiceMany}>
											<SelectField
												disabled={disabled || isSubmitting}
												error={
													errors.answers?.[question.id]
													&& touched.answers?.[question.id]
													&& t(errors.answers[question.id])
												}
												id={`answers[${question.id}]`}
												isLabelHidden
												label={question.question}
												name={`answers[${question.id}]`}
												onBlur={handleBlur}
												onChange={setFieldValue}
												options={nationalityOptions}
												placeholder={t('onboarding.steps.questionnaire.select')}
												searchable
												value={getValue(question, values)}
											/>
										</div>
										{question.note && (
											<div
												className={styles.note}
												// eslint-disable-next-line react/no-danger
												dangerouslySetInnerHTML={{ __html: question.note }}
											/>
										)}
									</>
								)}
								{question.type === 'ratioSlider' && (
									<>
										<h3 className={styles.question}>{question.question}</h3>
										{question.description && (
											<div
												className={styles.text}
												// eslint-disable-next-line react/no-danger
												dangerouslySetInnerHTML={{ __html: question.description }}
											/>
										)}
										<div className={styles.slider}>
											<Ranger
												disabled={disabled || isSubmitting}
												id="ratioSlider"
												max={RANGER_ESG_MAX}
												maxLabel={formatPercent(RANGER_ESG_MAX / 100)}
												min={RANGER_ESG_MIN}
												minLabel={question.id === 46 ? t('onboarding.steps.questionnaire.ratio_min') : formatPercent(RANGER_ESG_MIN / 100)}
												name={`answers[${question.id}]`}
												onChange={setFieldValue}
												step={10}
												value={getValue(question, values)}
											/>
										</div>
										{question.note && (
											<div
												className={styles.note}
												// eslint-disable-next-line react/no-danger
												dangerouslySetInnerHTML={{ __html: question.note }}
											/>
										)}
									</>
								)}
							</Fragment>
						))}
						{(
							questions[0]?.type !== 'investmentProfileChoice'
							&& !questions.some(({ id }) => id === ESG_FINAL_QUESTION_ID)
							&& !isAutoSubmit(skipQuestionIds)
						) && (
							<div className={styles.tooltipWrap}>
								<Button
									disabled={disabled || isSubmitting}
									isSubmit
									label={t(questions[0]?.type === 'text' ? 'onboarding.steps.questionnaire.agree' : 'onboarding.steps.questionnaire.continue')}
								/>
								{questions[0]?.tooltip && (
									<span className={styles.tooltip}>
										<Tooltip text={questions[0].tooltip}>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 512 512"
												width={15}
											>
												<path
													fill="currentColor"
													d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655
													90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947
													12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0
													14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0
													12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365
													0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"
												/>
											</svg>
										</Tooltip>
									</span>
								)}
							</div>
						)}
					</form>
				))(getSkipQuestionIds(values))}
			</Formik>
			{questions[0]?.type === 'investmentProfileChoice' && (
				<InvestorProfileChoice
					childId={childId}
					closeAccount={closeAccount}
					disabled={disabled}
					esgDefault={product === 'esg'}
					faqUrl={faqUrl}
					investmentQuestionnaireResetAvailable={investmentQuestionnaireResetAvailable}
					reloadQuestionnaire={reloadQuestionnaire}
				/>
			)}
		</>
	);
}

StepQuestions.propTypes = {
	answers: PropTypes.objectOf(PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	])).isRequired,
	childId: PropTypes.number,
	closeAccount: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	faqUrl: PropTypes.string.isRequired,
	investmentQuestionnaireResetAvailable: PropTypes.bool.isRequired,
	overwriteEsgPreferenceAnswers: PropTypes.func.isRequired,
	product: PropTypes.string,
	questions: PropTypes.arrayOf(
		PropTypes.shape({
			answers: PropTypes.arrayOf(
				PropTypes.shape({
					id: PropTypes.number.isRequired,
					answer: PropTypes.string.isRequired,
					position: PropTypes.number.isRequired,
				}),
			).isRequired,
			description: PropTypes.string,
			id: PropTypes.number.isRequired,
			note: PropTypes.string,
			position: PropTypes.number.isRequired,
			question: PropTypes.string.isRequired,
			questionnaire: PropTypes.string.isRequired,
			tooltip: PropTypes.string,
			type: PropTypes.string.isRequired,
			userAnswer: PropTypes.shape({
				id: PropTypes.number,
				value: PropTypes.number,
				text: PropTypes.string,
			}),
		}),
	).isRequired,
	reloadQuestionnaire: PropTypes.func.isRequired,
	resetEsgPreferenceQuestionnaire: PropTypes.func.isRequired,
	resetPosition: PropTypes.func.isRequired,
	saveQuestionnaireAnswer: PropTypes.func.isRequired,
	saveQuestionnaireAnswerResult: PropTypes.func.isRequired,
};

StepQuestions.defaultProps = {
	childId: null,
	disabled: false,
	product: null,
};

AutoSubmit.propTypes = {
	name: PropTypes.string.isRequired,
	setFieldValue: PropTypes.func.isRequired,
	submitForm: PropTypes.func.isRequired,
};
