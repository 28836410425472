import React from 'react';
import { useTranslation } from 'react-i18next';

import {
	Col,
	Container,
	Row,
} from '../../../components/layout';
import {
	fetchProfileStatistics,
} from '../../../utils/api';
import useFetchAuth from '../../../utils/useFetchAuth';
import useFormatNumber from '../../../utils/useFormatNumber';

import styles from './Stats.module.scss';

const useFetchProfileStatistics = useFetchAuth(fetchProfileStatistics);

export default function Stats() {
	const [statistics, loading, error] = useFetchProfileStatistics({});
	const formatNumber = useFormatNumber();
	const [t] = useTranslation();

	if (loading || error) return null;

	return (
		<div className={styles.root}>
			<Container>
				<Row>
					<Col lg={12}>
						<div className={`${styles.title} ${styles.titleNarrow}`}>
							{t('referrals.stats.1')}
						</div>
						<div className={styles.number}>
							{formatNumber(statistics.allUsers ?? null)}
						</div>
					</Col>
					<Col lg={6}>
						<div className={`${styles.title} ${styles.titleNarrow}`}>
							{t('referrals.stats.2')}
						</div>
						<div className={styles.number}>
							{formatNumber(statistics.allUsersFromPeriod ?? null)}
						</div>
					</Col>
					<Col lg={6}>
						<div className={styles.title}>
							{t('referrals.stats.3')}
						</div>
						<div className={styles.number}>
							{formatNumber(statistics.allClientsFromPeriodWithPortfolioOverThreshold ?? null)}
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
}
