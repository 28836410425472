import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
	Documents,
	EmployerList,
	ManualQuestionnaireTriggerModal,
	PersonalInformationForm,
	PersonalInformationModal,
} from '../../components/common';
import {
	Col,
	Container,
	Section,
	Row,
} from '../../components/layout';
import {
	Accordion,
	Box,
	EmailLink,
	Loader,
	TopUpModal,
} from '../../components/ui';
import {
	useCountry,
	useLanguage,
} from '../../contexts/LocaleContext';
import {
	useUserContext,
	useUserReload,
} from '../../contexts/UserContext';
import {
	fetchChildrenList,
	fetchClientCheck,
	fetchProfileBrokerAccounts,
	fetchProfilePortfolio,
	fetchProfilePortfolioWithdrawalPensionCheck,
	fetchUserCurrency,
	fetchUserDeviceToken,
} from '../../utils/api';
import convertUser from '../../utils/convertUser';
import { toNumber } from '../../utils/number';
import useAnchorLoader from '../../utils/useAnchorLoader';
import useFetchAuth from '../../utils/useFetchAuth';
import useFetchWithReload from '../../utils/useFetchWithReload';
import BankAccountsControl from './BankAccountsControl';
import ChildList from './ChildList';
import Closure from './Closure';
import CurrencySelect from './CurrencySelect';
import DepositDefaultPortfolio from './DepositDefaultPortfolio';
import DeviceTokens from './DeviceTokens';
import PasswordChange from './PasswordChange';
import ProfilePreference from './ProfilePreference';
import TwoFactorAuthenticationControl from './TwoFactorAuthenticationControl';

import styles from './Account.module.scss';

const useFetchChildrenList = useFetchWithReload(fetchChildrenList);
const useFetchClientCheck = useFetchAuth(fetchClientCheck);
const useFetchProfilePortfolioWithdrawalPensionCheck = useFetchAuth(fetchProfilePortfolioWithdrawalPensionCheck);
const useFetchProfileBrokerAccounts = useFetchAuth(fetchProfileBrokerAccounts);
const useFetchProfilePortfolio = useFetchAuth(fetchProfilePortfolio);
const useFetchUserCurrency = useFetchAuth(fetchUserCurrency);
const useFetchUserDeviceToken = useFetchWithReload(fetchUserDeviceToken);

export default function Account({
	childOnboardingUrl,
	clientCheckUrl,
	faqUrl,
	portfolioUrl,
	conditionsUrl,
	termsUrl,
	personalInfoAgreementUrl,
	informationSheetUrl,
	complaintsProcedureUrl,
	annualReportUrl,
	annualReportTemplatesUrl,
	esgInfolistUrl,
	esgSustainabilityUrl,
}) {
	const [t] = useTranslation();
	const country = useCountry();
	const language = useLanguage();
	const [user] = useUserContext();
	const reloadUser = useUserReload();
	const [clientData] = useFetchClientCheck(null);
	const [childrenList, , , , reloadChildrenList] = useFetchChildrenList(null, !!user?.child);
	const [brokerAccounts, loadingBrokerAccounts] = useFetchProfileBrokerAccounts(null, language);
	const [portfolios] = useFetchProfilePortfolio(null, language);
	const [pensionCheck] = useFetchProfilePortfolioWithdrawalPensionCheck(null);
	const [currencies] = useFetchUserCurrency(null, language);
	const [tokens, , , , reloadTokens] = useFetchUserDeviceToken(null);
	const [showNameModal, setShowNameModal] = useState(false);
	const employersAnchor = useAnchorLoader(t('anchors.employerList'));
	const closureAnchor = useAnchorLoader(t('anchors.accountClosure'));
	const formatDate = (date) => new Date(date).toLocaleDateString(language);

	const depositDefaultPortfolioId = user !== null && user.deposit_default_portfolio_id !== null
		? toNumber(user.deposit_default_portfolio_id)
		: null;

	if (user === null) {
		return <Loader fullPage />;
	}

	return (
		<>
			<Section bordered>
				<Container>
					<Row>
						<Col md={6} lg={5}>
							<h1 className={styles.title}>{t('account.title')}</h1>
							<p className={styles.subtitle}>
								<Trans i18nKey="account.subtitle">
									<EmailLink />
								</Trans>
							</p>
							<PersonalInformationForm
								reloadUser={reloadUser}
								setShowNameModal={setShowNameModal}
								user={convertUser(user)}
							/>
							<PersonalInformationModal
								reloadUser={reloadUser}
								showNameModal={showNameModal}
								setShowNameModal={setShowNameModal}
							/>
						</Col>
						<Col md={6} lg={5}>
							<Box>
								<div className={styles.boxText}>
									<TopUpModal
										key={depositDefaultPortfolioId}
										defaultPortfolioId={depositDefaultPortfolioId}
										portfolios={portfolios?.active ?? null}
									/>
									<div className={styles.tradingDay}>
										{!loadingBrokerAccounts && brokerAccounts !== null && (
											<>
												{t('account.tradingDay')}
												{' '}
												<strong>
													{formatDate(brokerAccounts.nextTradingDate)}
												</strong>
											</>
										)}
									</div>
									<Link
										className={styles.linkText}
										to={faqUrl}
									>
										{t('account.faqLink')}
										<svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 512 512"
										>
											<path
												fill="currentColor"
												d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"
											/>
										</svg>
									</Link>
								</div>
								<PasswordChange />
								<TwoFactorAuthenticationControl />
								{tokens && tokens.length > 0 && (
									<DeviceTokens
										deviceData={tokens}
										reloadTokens={reloadTokens}
									/>
								)}
								<CurrencySelect
									currencies={currencies}
									reloadUser={reloadUser}
								/>
								<DepositDefaultPortfolio
									portfolios={portfolios?.active ?? null}
									reloadUser={reloadUser}
								/>
								<ProfilePreference />
								<BankAccountsControl
									accounts={user.bank_accounts}
									reloadUser={reloadUser}
								/>
								{!user.child && (
									<ManualQuestionnaireTriggerModal
										clientCheckUrl={clientCheckUrl}
										lastCheck={clientData?.lastQuestionnaireCheckAt}
										nextCheck={clientData?.nextManualQuestionnaireCheckAt}
									/>
								)}
							</Box>
						</Col>
					</Row>
				</Container>
			</Section>

			<Section condensed>
				<Container>
					{!user.child && childrenList !== null && childrenList.length > 0 && (
						<Accordion title={t('account.childList.title')}>
							<ChildList
								childrenList={childrenList}
								childOnboardingUrl={childOnboardingUrl}
								clientCheckUrl={clientCheckUrl}
								currencies={currencies}
								portfolioUrl={portfolioUrl}
								portfolios={portfolios?.active ?? []}
								reloadChildrenList={reloadChildrenList}
								reloadUser={reloadUser}
								userClosure={user.account_closure}
								userCurrency={user.currency_code}
							/>
						</Accordion>
					)}

					{country === 'CZ' && user.pensionPortfolioId !== null && (
						<Accordion
							defaultOpen={employersAnchor}
							id={t('anchors.employerList')}
							title={t('account.employerList.title')}
						>
							<EmployerList
								portfolios={portfolios?.active ?? null}
							/>
						</Accordion>
					)}

					<Accordion
						defaultOpen={closureAnchor}
						id={t('anchors.accountClosure')}
						title={t('account.withdrawals.closureTitle')}
					>
						<Closure
							childClosure={!user.child && !!childrenList?.some((child) => child.accountClosure)}
							currencies={currencies}
							pensionCheck={pensionCheck}
							portfolioUrl={portfolioUrl}
							reloadChildrenList={reloadChildrenList}
							reloadUser={reloadUser}
							userChild={user.child}
							userClosure={user.account_closure}
							userClosureClosedChange={user.account_closure_closed_change}
							userCurrency={user.currency_code}
						/>
					</Accordion>

					<Accordion title={t('documents.title')}>
						<Documents
							annualReportTemplatesUrl={annualReportTemplatesUrl}
							annualReportUrl={annualReportUrl}
							complaintsProcedureUrl={complaintsProcedureUrl}
							conditionsUrl={conditionsUrl}
							esgInfolistUrl={esgInfolistUrl}
							esgSustainabilityUrl={esgSustainabilityUrl}
							informationSheetUrl={informationSheetUrl}
							personalInfoAgreementUrl={personalInfoAgreementUrl}
							sectionWrap={false}
							termsUrl={termsUrl}
						/>
					</Accordion>
				</Container>
			</Section>
		</>
	);
}

Account.propTypes = {
	childOnboardingUrl: PropTypes.string.isRequired,
	clientCheckUrl: PropTypes.string.isRequired,
	faqUrl: PropTypes.string.isRequired,
	portfolioUrl: PropTypes.string.isRequired,
	conditionsUrl: PropTypes.string.isRequired,
	termsUrl: PropTypes.string.isRequired,
	personalInfoAgreementUrl: PropTypes.string.isRequired,
	informationSheetUrl: PropTypes.string.isRequired,
	complaintsProcedureUrl: PropTypes.string.isRequired,
	annualReportUrl: PropTypes.string.isRequired,
	annualReportTemplatesUrl: PropTypes.string.isRequired,
	esgInfolistUrl: PropTypes.string.isRequired,
	esgSustainabilityUrl: PropTypes.string.isRequired,
};
