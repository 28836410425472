import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
	Col,
	Container,
	Row,
} from '../../../components/layout';
import { Accordion } from '../../../components/ui';
import ChildCancelClosure from '../ChildCancelClosure';
import ChildOnboarding from '../ChildOnboarding';
import ChildPersonalInformation from '../ChildPersonalInformation';

import styles from './ChildList.module.scss';

export default function ChildList({
	childrenList,
	childOnboardingUrl,
	clientCheckUrl,
	currencies,
	portfolioUrl,
	portfolios,
	reloadChildrenList,
	reloadUser,
	userClosure,
	userCurrency,
}) {
	const [t] = useTranslation();

	return (
		<Container>
			<Row>
				<Col xl={6} lg={8}>
					<p className={styles.text}>
						{t('account.childList.text')}
					</p>
					{childrenList.filter((child) => !child.activeClient && !child.accountClosure).map((child) => (
						<ChildOnboarding
							key={child.id}
							child={child}
							childOnboardingUrl={childOnboardingUrl}
							reloadChildrenList={reloadChildrenList}
							reloadUser={reloadUser}
						/>
					))}
					{childrenList.filter((child) => child.activeClient && !child.accountClosure).map((child) => (
						<Accordion key={child.id} title={`${child.firstName} ${child.lastName}`} small>
							<ChildPersonalInformation
								child={child}
								clientCheckUrl={clientCheckUrl}
								currencies={currencies}
								portfolioUrl={portfolioUrl}
								portfolios={portfolios}
								reloadChildrenList={reloadChildrenList}
								reloadUser={reloadUser}
								userCurrency={userCurrency}
							/>
						</Accordion>
					))}
					{childrenList.filter((child) => child.accountClosure).map((child) => (
						<ChildCancelClosure
							key={child.id}
							child={child}
							reloadChildrenList={reloadChildrenList}
							reloadUser={reloadUser}
							userClosure={userClosure}
						/>
					))}
				</Col>
			</Row>
		</Container>
	);
}

ChildList.propTypes = {
	childrenList: PropTypes.arrayOf(PropTypes.shape({
		accountClosure: PropTypes.bool.isRequired,
		activeClient: PropTypes.bool.isRequired,
		checkEmailAndPhone: PropTypes.bool.isRequired,
		email: PropTypes.string,
		firstName: PropTypes.string.isRequired,
		id: PropTypes.number.isRequired,
		lastName: PropTypes.string.isRequired,
		phoneNumber: PropTypes.string,
		phonePrefix: PropTypes.string,
	}).isRequired).isRequired,
	clientCheckUrl: PropTypes.string.isRequired,
	currencies: PropTypes.arrayOf(PropTypes.shape({
		code: PropTypes.string.isRequired,
		codeShort: PropTypes.string.isRequired,
		codeShortBefore: PropTypes.bool.isRequired,
	}).isRequired),
	childOnboardingUrl: PropTypes.string.isRequired,
	portfolioUrl: PropTypes.string.isRequired,
	portfolios: PropTypes.arrayOf(PropTypes.shape({
		categoryName: PropTypes.string.isRequired,
		child: PropTypes.shape({
			firstName: PropTypes.string.isRequired,
			id: PropTypes.number.isRequired,
		}),
		faceImageFile: PropTypes.string.isRequired,
		id: PropTypes.number.isRequired,
		name: PropTypes.string,
	}).isRequired).isRequired,
	reloadChildrenList: PropTypes.func.isRequired,
	reloadUser: PropTypes.func.isRequired,
	userClosure: PropTypes.bool,
	userCurrency: PropTypes.string,
};

ChildList.defaultProps = {
	currencies: null,
	userClosure: false,
	userCurrency: null,
};
